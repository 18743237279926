var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{staticClass:"modal-large",attrs:{"okButtonProps":{ disabled: true },"visible":_vm.showLicenseScan,"title":`Scan License`,"okText":"NEXT","cancelText":"CANCEL","centered":""},on:{"ok":_vm.scanToDetails,"cancel":function($event){return _vm.$emit('close')}}},[(_vm.type === 'dl')?_c('a-card',{staticClass:"my-3",class:{
            'border-success':
                _vm.purchaserScan.fileFront && !_vm.purchaserScan.errorFront,
            'border-danger':
                _vm.purchaserScan.errorFront ||
                (_vm.showScanError && !_vm.purchaserScan.fileFront),
        }},[_c('div',{staticClass:"dF jSB aC"},[_c('span',[_c('span',[_vm._v(" Front Photo "),(
                            _vm.purchaserScan.errorFront ||
                            (_vm.showScanError && !_vm.purchaserScan.fileFront)
                        )?_c('a-icon',{staticClass:"text-danger",attrs:{"type":"close"}}):_vm._e()],1),(
                        _vm.purchaserScan.errorFront ||
                        (_vm.showScanError && !_vm.purchaserScan.fileFront)
                    )?_c('small',{staticClass:"block text-sm"},[_vm._v("There was a problem retrieving data. Please scan again.")]):_vm._e()]),_c('div',[_c('a-upload',{attrs:{"showUploadList":false,"beforeUpload":(e) => _vm.beforeScan(e, 'front')}},[(!_vm.purchaserScan.fileFront)?_c('a-button',{attrs:{"type":"secondary","icon":"scan"}},[_vm._v("Scan")]):_c('a-icon',{staticClass:"text-success",attrs:{"type":"check"}})],1)],1)])]):_vm._e(),_c('a-card',{key:_vm.newTime,staticClass:"my-3",class:{
            'border-success':
                _vm.purchaserScan.data.firstName && !_vm.purchaserScan.errorBack,
            'border-danger':
                _vm.purchaserScan.errorBack ||
                (_vm.showScanError && !_vm.purchaserScan.fileBack),
        }},[_c('div',{staticClass:"dF jSB aC"},[_c('span',[_c('span',[_vm._v(" Back Photo "),(
                            _vm.purchaserScan.errorBack ||
                            (_vm.showScanError && !_vm.purchaserScan.fileBack)
                        )?_c('a-icon',{staticClass:"text-danger",attrs:{"type":"close"}}):_vm._e()],1),(
                        _vm.purchaserScan.errorBack ||
                        (_vm.showScanError && !_vm.purchaserScan.fileBack)
                    )?_c('small',{staticClass:"block text-sm"},[_vm._v("There was a problem retrieving data. Please scan again.")]):(_vm.purchaserScan.data.firstName)?_c('small',{staticClass:"block text-sm"},[_vm._v(_vm._s(_vm.whoName)+": "),_c('strong',[_vm._v(_vm._s(_vm.purchaserScan.data.firstName)+" "+_vm._s(_vm.purchaserScan.data.lastName))])]):_vm._e()]),_c('div',[(!_vm.purchaserScan.data.firstName)?_c('a-upload',{attrs:{"showUploadList":false,"beforeUpload":(e) => _vm.beforeScan(e, 'back')}},[_c('a-button',{attrs:{"type":"secondary","icon":"scan"}},[_vm._v("Scan")])],1):_c('a-icon',{staticClass:"text-success",attrs:{"type":"check"}})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }