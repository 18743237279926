export const isProductAvailable = async (transaction,self) => {
    if (self.instance.productType == 'lowrise'){
        console.log('CHECKING IF UNIT/LOT AVAILABLE')
        let data1 = await self.$api.get(`/architectural-controls/:instance/availablelots`)
        let checkLot = data1.data.findIndex( x => x.id === transaction.product.lot.id)
        if (checkLot == -1){
            return false
        }
        console.log('UNIT/LOT IS STILL AVAILABLE')

    } else {

        console.log('CHECKING IF UNIT/LOT AVAILABLE')
        let data1 = await self.$api.get(`/unit-groups/:instance`)
        let findFloor = data1.data.find( x => x.id == transaction.product.floor.id)
        let findIndex = findFloor.units.findIndex( y => y.id == transaction.product.unit.id)
        console.log('SELECTED UNIT/LOT', findFloor.units[findIndex])
        if (findFloor.units[findIndex].salesStatus != 'available'){
            self.$store.commit('LOAD', false)
            return false
        }
        console.log('UNIT/LOT IS STILL AVAILABLE')

    }
}
export const generateContractPreview = async (transaction,self) => {
	let sendTransaction = JSON.parse(JSON.stringify(transaction));
	if (sendTransaction?.other?.customFields && Object.keys(sendTransaction.other.customFields).some(key => key.includes("variable_"))){
		for (let key in sendTransaction.other.customFields){
			if (key.includes('variable_')){
				let text = sendTransaction.other.customFields[key];
				if (text.includes('%hide%')){
					text = '';
				} else {
					sendTransaction.other.customFieldVariables[key].forEach(x => {
						text = text.replaceAll(`{${x.label}}`, x.value);
					})
				}
				sendTransaction.other.customFields[key] = text;
			}
		}
	}
    let {data:key} = await self.$api.post(`/contract-templates/:instance/${self.currentContract.id}/preview`, sendTransaction)
    let {data:url} = await self.$api.get(`/contract-templates/:instance/getfile?key=${key}`);
    return {key,url}
}

export const getContractTemplateName = (transaction, productType = 'lowrise') => {
	let name = ''
	if (productType === 'lowrise' && transaction.product && transaction.product.lot && transaction.product.lot.name) {
		name = `Lot ${transaction.product.lot.name} (${transaction.product.unit && transaction.product.unit.unitGroup && transaction.product.unit.unitGroup.name} ${transaction.product.unit && transaction.product.unit.name} - ${transaction.product.unit && transaction.product.unit.unitGroup && transaction.product.unit.unitGroup.size}' ${transaction.product.unit && transaction.product.unit.unitGroup && transaction.product.unit.unitGroup.type})`;
	} else if (productType === 'highrise' && transaction.product && transaction.product.floor && transaction.product.unit) {
		name = `${transaction.product.floor.name} (#${transaction.product.unit.unitNumber})`
	}

	let purchaserName = transaction.purchasers[0] && transaction.purchasers[0].fullName

	if (purchaserName && purchaserName.trim()) {
		name += ` - (${purchaserName.trim()})`
	}

	return name.trim();
}
